export const Colors = {
  boneWhite: "#e6e3da",
  oceanGray: "#dadde6",
};

export const Elevation = {
  niceLilElevation:
    "0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12)",
  noElevation:
    "0px 0px 0px 0px rgba(0 , 0, 0, 0)",
  lilBiggerElevation:
    "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
};
